.App {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    gap: 0px;

    height: 95vh;
    width: 95vw;

    margin: auto;
}

path {
    transition: d var(--path-transition-time) linear;
}

.Controlls {
    width: 100%;
    padding: 1rem;
}

@media (hover: hover) {
    .Controlls {
        opacity: 0%;
        transition: opacity 0.2s linear;
    }
    
    .Controlls:hover {
        opacity: 100%;
    }
}

.Controlls button { 
    width: 3rem;
}

.Controlls #value{
    padding: 0 0.5rem;
}
